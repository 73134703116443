$hamburger-padding-x                       : 15px !default;
$hamburger-padding-y                       : 20px !default;
$hamburger-layer-width                     : 30px !default;
$hamburger-layer-height                    : 3px !default;
$hamburger-layer-spacing                   : 4px !default;
$hamburger-layer-color                     : $gray-dark !default;
$hamburger-layer-border-radius             : 3px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(80%) !default;

.hamburger {
	padding: 16px 15px 12px !important;
}