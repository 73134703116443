.ministry-left {
	float: left;
}

.ministry-right {
	box-shadow: 0px 1px 2px rgba($black, 0.2);
	float: right;
	margin-bottom: $double-margin;
}

/* Input styles for extra small screens and up */
@media (min-width: $screen-xs-min) {
	.ministry-left {
		width: 100%;
	}
	.ministry-right {
		display: none;
	}

	.occ {
		.occ-title {
			margin-top: 0px;
		}

		p {
			margin-bottom: 30px;
		}
	}

	#ministry-overview {
		#hero-content {
			height: 400px;

			.hero-cta {
				@include position(absolute, null, null, 0px, null);
				padding: 15px;
			}
		}
	}
}
/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	#ministry-overview {
		#hero-content {
			height: 450px;
		}
	}
}
/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {
	.ministry-left {
		width: calc(100% - 350px);
	}
	.ministry-right {
		width: 320px;
		display: block;
	}

	#ministry-overview {
		#hero-content {
			height: 550px;
		}
	}
}
/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {
	.occ {
		.occ-title {
			margin-top: 22px;
		}
		p {
			margin-bottom: 0px;
		}
	}
}