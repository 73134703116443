%no-mrg-top, .no-mrg-top,
%no-top-mrg, .no-top-mrg {
	margin-top: 0px !important;
}

%no-mrg-btm, .no-mrg-btm,
%no-btm-mrg, .no-btm-mrg {
	margin-bottom: 0px !important;
}

%no-mrg, .no-mrg {
	margin: 0 !important;
}


.double-margin-top {
	margin-top: $double-margin;
}

.double-margin-bottom {
	margin-bottom: $double-margin;
}

.double-margin-top-bottom {
	margin: $double-margin 0;
}

//////////////////////////////////

.icon-margin-left {
	margin-left: 5px;
}

.icon-margin-right {
	margin-right: 5px;
}