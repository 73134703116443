/// ------------------------------------------------------------- ///
///                      Typography Styles                        ///
///     Check _variables.scss before making adjustsments here     ///
/// ------------------------------------------------------------- ///
$shadow: 0px 2px 1px rgba(darken($lighter-brown, 20%), .8);


.header-text {
	display: inline-block;

	h1 {
		margin: 0 auto;
		font-size: 70px !important;
		text-transform: uppercase;
		font-weight: bold;
		opacity: .7;
		color: $lighter-brown;
		text-shadow: $shadow;
	}

	hr {
		opacity: .7;
		margin: 0px;
		border-top: 12px solid $lighter-brown;
		border-bottom-right-radius: 12px;
		border-bottom-left-radius: 12px;
		box-shadow: $shadow;
	}
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
}

/* Input styles for up to the max width of xs screen*/
@media (max-width: $screen-xs-min) {}
@media (max-width: $screen-sm-min) {}
@media (max-width: $screen-md-min) {}
@media (max-width: $screen-lg-min) {}
