/// ------------------------------------------------------------- ///
///                      Global Mixins                            ///
///      Used to create mixins that can be used on several        ///
///      selectors globally. Generic mixins.                      ///
/// ------------------------------------------------------------- ///

// 1 - Prefix
// 2 - Hi Res Images
// 3 - Animations
// 4 - Transition
// 5 - Typography 
// 6 - Borders
// 7 - Center
// 8 - Box Shadow
// 9 - Position
// 10 - Font Size
// 11 - Background


/// ------------------------------------------------------------- ///
///                           1 - Prefix                          ///
/// ------------------------------------------------------------- ///
@mixin prefix($property, $value, $prefixes: ()) {
	@each $prefix in $prefixes {
		-#{$prefix}-#{$propery}: $value;
	}

	#{$property}: $value;
}


/// ------------------------------------------------------------- ///
///                       2 - Hi-res Images                       ///
/// ------------------------------------------------------------- ///
@mixin retina-display {
  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    @content;
  }
}


/// ------------------------------------------------------------- ///
///                         3 - Animations                        ///
/// ------------------------------------------------------------- ///
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}


/// ------------------------------------------------------------- ///
///                         4 - Transitions                       ///
/// ------------------------------------------------------------- ///
@mixin transition($transition) {
	-webkit-transition: $transition;
	   -moz-transition: $transition;
		  -ms-transition: $transition;
		 			transition: $transition;
}

// Example //
/* -------------------------------------------------------------
 a {
   color: gray;
   @include transition(color .3s ease);
   &:hover {
     color: black;
   }
}
------------------------------------------------------------ */


/// ------------------------------------------------------------- ///
///                       5 - Typography                          ///
/// ------------------------------------------------------------- ///
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size)
}


/// ------------------------------------------------------------- ///
///                      6 - Borders                              ///
/// ------------------------------------------------------------- ///
@mixin border($width, $style, $color) {
  border: {
    color: $color;
    width: $width;
    style: $style;
  }
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}
@mixin top-left-border-radius($radius) {
  -webkit-border-top-left-radius: $radius;
     -moz-border-radius-topleft: $radius;
          border-top-left-radius: $radius;
}
@mixin top-right-border-radius($radius) {
  -webkit-border-top-right-radius: $radius;
     -moz-border-radius-topright: $radius;
          border-top-right-radius: $radius;
}
@mixin bottom-left-border-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
     -moz-border-radius-bottomleft: $radius;
          border-bottom-left-radius: $radius;
}
@mixin bottom-right-border-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
     -moz-border-radius-bottomright: $radius;
          border-bottom-right-radius: $radius;
}
// Example //
/* -------------------------------------------------------------
	.btn {
		@include border(2px, solid, $black);
   	@include border-radius(4px);
   }
------------------------------------------------------------ */

/// ------------------------------------------------------------- ///
///                    7 - Center Position                        ///  
/// ------------------------------------------------------------- ///
@mixin center($position) {
	position: absolute;

	@if $position == 'verticalOffset' {
		top: calc(50% - 80px);
		-webkit-transform: translateY(calc(-50% + 50px));
		   -moz-transform: translateY(calc(-50% + 50px));
			  -ms-transform: translateY(calc(-50% + 50px));
						transform: translateY(calc(-50% + 50px));
	}

	@if $position == 'vertical' {
		top: 50%;
		-webkit-transform: translateY(-50%);
		   -moz-transform: translateY(-50%);
			  -ms-transform: translateY(-50%);
						transform: translateY(-50%);
	}

	@else if $position == 'horizontal' {
		left: 50%;
		-webkit-transform: translateX(-50%);
		   -moz-transform: translateX(-50%);
			  -ms-transform: translateX(-50%);
						transform: translateX(-50%);
	}

	@else if $position == 'both' {
		left: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		   -moz-transform: translate(-50%, -50%);
			  -ms-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
	}
}


/// ------------------------------------------------------------- ///
///                      8 - Drop shadow                          ///
/// ------------------------------------------------------------- ///
@mixin box-shadow($drop-shadow) {
	-webkit-box-shadow: $drop-shadow;
	   -moz-box-shadow: $drop-shadow;
		 			box-shadow: $drop-shadow;
}


/// ------------------------------------------------------------- ///
///                      9 - Position                             ///
/// ------------------------------------------------------------- ///
@mixin position($position: null, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

/// ------------------------------------------------------------- ///
///                      10 - Font Size                            ///
/// ------------------------------------------------------------- ///
@mixin font-sizes($base-size) {
	p, label, input, textarea, a, span, .btn, body {
		font-size: $base-size !important;
	}
	h1 {
		font-size: floor(($base-size * 2.6)) !important;
	}
	h2 {
		font-size: floor(($base-size * 2.15)) !important;
	}
	h3 {
		font-size: ceil(($base-size * 1.7)) !important;
	}
	h4 {
		font-size: ceil(($base-size * 1.25)) !important;
	}
	h5 {
		font-size: $base-size !important;
	}
	h6 {
		font-size: ceil(($base-size * 0.85)) !important;
	}
}

/// ------------------------------------------------------------- ///
///                      11 - Background                          ///
/// ------------------------------------------------------------- ///
@mixin background($url, $position, $size) {
	background: url($url);
	background-position: $position;
	background-size: $size;
}
