%textbox {
	// border: 0px;
	-webkit-appearance: none;
	border-left: 2px solid $medium-tan !important;
	border-right: 2px solid $medium-tan !important;
	border-top: 2px solid $medium-tan !important;
	border-bottom: 2px solid $brand-primary !important;
	box-shadow: 0px 1px 1px rgba(0,0,0,0.3);
	min-width: 100%;
	max-width: 100%;
	display: block;
    height: 36px;
	padding: 6px 12px;
	line-height: 1.42857;
    color: #555;
    background-color: #fff;
	background-image: none;
	border-radius: 0px;
	transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;

	&:focus {
		box-shadow: 0px 0px 5px rgba($brand-primary, 0.6);
		border-bottom: 2px solid $brand-primary;
	}
}

.error {
	color: $brand-danger;
}


label {
	font-weight: 600 !important;
}

input[type=text],
input[type=tel],
input[type=email],
input[type=password],
textarea {
	@extend %textbox;
}

textarea {
	min-height: 100px;
}


#fields-methodOfContact label {
	position: relative;
	display: inline-block;
	padding-left: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;
	margin-top: $default-margin;
}

#fields-methodOfContact label input[type="radio"] {
	position: absolute;
	margin-left: -20px;
}

#fields-methodOfContact label:not(:first-of-type) {
	margin-left: 10px !important;
}