$bootstrap-sass-asset-helper: false !default;
@import url('https://fonts.googleapis.com/css?family=Muli:400,400i,600,800,800i');

/** ------------------------------------------------------------- **/
/**               Bootstrap Global Variables                      **/
/** ------------------------------------------------------------- **/

// 1 - Colors
// 2 - Scaffolding
// 3 - Typography
// 4 - Iconography
// 5 - Components
// 6 - Tables
// 7 - Buttons
// 8 - Forms
// 9 - Dropdowns
// 10 - Z-index Master List
// 11 - Media Query breakpoints
// 12 - Grid Systems
// 13 - Container sizes
// 14 - Navbar
// 15 - Navs
// 16 - Tabs
// 17 - Pills
// 18 - Pagination
// 19 - Pager
// 20 - Jumbotron
// 21 - Form States and Alerts
// 22 - Tooltips
// 23 - Popovers
// 24 - Labels
// 25 - Modals
// 26 - Alerts
// 27 - Progress Bars
// 28 - List Group
// 29 - Panels
// 30 - Thumbnails
// 31 - Wells
// 32 - Badges
// 33 - Breadcrumbs
// 34 - Carousel
// 35 - Close
// 36 - Code
// 37 - Type




/** ------------------------------------------------------------- **/
/**                          1 - Colors                           **/
/** ------------------------------------------------------------- **/

$black:									 #000 !default;
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee
$white:					 				#fff !default;

// $brand-primary:			#F59500 !default;
$brand-primary: #ef9d20 !default;
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

$lightest-tan: #fdfcf8;
$medium-tan: #F6F5F0;
$darker-tan: #f3f1ea;
$lighter-brown: #c7b68e;
$darker-brown: #a38d5a;



/** ------------------------------------------------------------- **/
/**                     2 - Scaffolding                           **/
/**        Settings for some of the most global styles.           **/
/** ------------------------------------------------------------- **/


$body-bg:               $darker-tan !default;
$text-color:            $gray-dark !default;
$link-color:            $brand-primary !default;
$link-hover-color:      darken($link-color, 5%) !default;
$link-hover-decoration: underline !default;




/** ------------------------------------------------------------- **/
/**                      3 - Typography                           **/
/** ------------------------------------------------------------- **/

$font-family-sans-serif:  Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
$font-family-custom: 	'Muli', sans-serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-custom !default;

$font-size-base:          16px !default;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

// Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
// Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

$headings-font-family:    inherit !default; // By default, this inherits from the `<body>`.
$headings-font-weight:    600 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;




/** ------------------------------------------------------------- **/
/**                       4 - Iconography                         **/
/** ------------------------------------------------------------- **/
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.
// Load fonts from this directory.
// [converter] If $bootstrap-sass-asset-helper if used, provide path relative to the assets load path.
// [converter] This is because some asset helpers, such as Sprockets, do not work with file-relative paths.
$icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "../fonts/bootstrap/") !default;

// File name for all font files.
$icon-font-name:          "glyphicons-halflings-regular" !default;
// Element ID within SVG icon file.
$icon-font-svg-id:        "glyphicons_halflingsregular" !default;




/** ------------------------------------------------------------- **/
/**                         5 - Components                        **/
/** ------------------------------------------------------------- **/

$padding-base-vertical:     6px !default;
$padding-base-horizontal:   12px !default;

$padding-large-vertical:    10px !default;
$padding-large-horizontal:  16px !default;

$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;

$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;

$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;

// $border-radius-base:        4px !default;
// $border-radius-large:       6px !default;
// $border-radius-small:       3px !default;

$border-radius-base:        0px !default;
$border-radius-large:       0px !default;
$border-radius-small:       0px !default;

// Global color for active items (e.g., navs or dropdowns).
$component-active-color:    #fff !default;
// Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $brand-primary !default;
// Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base:          4px !default;
// Carets increase slightly in size for larger components.
$caret-width-large:         5px !default;




/** ------------------------------------------------------------- **/
/**                         6 - Tables                            **/
/** ------------------------------------------------------------- **/

$table-cell-padding:            8px !default;
$table-condensed-cell-padding:  5px !default;
$table-bg:                      transparent !default;
$table-bg-accent:               #f9f9f9 !default; // Background color used for `.table-striped`.
$table-bg-hover:                #f5f5f5 !default;
$table-bg-active:               $table-bg-hover !default;
$table-border-color:            #ddd !default;




/** ------------------------------------------------------------- **/
/**                         7 - Buttons                           **/
/** ------------------------------------------------------------- **/

$btn-font-weight:                normal !default;

$btn-default-color:              #333 !default;
$btn-default-bg:                 #fff !default;
$btn-default-border:             #ccc !default;

$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             darken($btn-primary-bg, 5%) !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;

$btn-info-color:                 #fff !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                darken($btn-info-bg, 5%) !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color:        $gray-light !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base !default;
$btn-border-radius-large:        $border-radius-large !default;
$btn-border-radius-small:        $border-radius-small !default;




/** ------------------------------------------------------------- **/
/**                          8 - Forms                            **/
/** ------------------------------------------------------------- **/

$input-bg:                       #fff !default; //Input bg
$input-bg-disabled:              $gray-lighter !default; // Disabled bg
$input-color:                    $gray !default;
$input-border:                   #ccc !default;

// Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            $border-radius-base !default;
$input-border-radius-large:      $border-radius-large !default; // Large `.form-control` border radius
$input-border-radius-small:      $border-radius-small !default; // Small `.form-control` border radius
$input-border-focus:             #66afe9 !default; // Border color for inputs on focus
$input-color-placeholder:        #999 !default; // Placeholder text color
// Default `.form-control` height
$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
// Large `.form-control` height
$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
// Small `.form-control` height
$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;
$form-group-margin-bottom:       15px !default; // `.form-group` margin

$legend-color:                   $gray-dark !default;
$legend-border-color:            #e5e5e5 !default;

// Background color for textual input addons
$input-group-addon-bg:           $gray-lighter !default;
// Border color for textual input addons
$input-group-addon-border-color: $input-border !default;
// Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed !default;




/** ------------------------------------------------------------- **/
/**                        9 - Dropdowns                          **/
/** ------------------------------------------------------------- **/

$dropdown-bg:                    #fff !default;
$dropdown-border:                rgba(0,0,0,.15) !default;
$dropdown-fallback-border:       #ccc !default;
$dropdown-divider-bg:            #e5e5e5 !default;

$dropdown-link-color:            $gray-dark !default;
$dropdown-link-hover-color:      darken($gray-dark, 5%) !default;
$dropdown-link-hover-bg:         $medium-tan !default;

$dropdown-link-active-color:     $component-active-color !default;
$dropdown-link-active-bg:        $component-active-bg !default;

$dropdown-link-disabled-color:   $gray-light !default;
$dropdown-header-color:          $gray-light !default;

$dropdown-caret-color:           #000 !default;




/** ------------------------------------------------------------- **/
/**                    10 - Z-index master list                   **/
/** ------------------------------------------------------------- **/
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-modal-background:  1040 !default;
$zindex-modal:             1050 !default;




/** ------------------------------------------------------------- **/
/**                 11 - Media queries breakpoints                **/
/** ------------------------------------------------------------- **/
//## Define the breakpoints at which your layout will change, 
//## adapting to different screen sizes.

// Extra small screen / phone
// Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  320px !default;
// Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
// Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
// Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
// Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
// Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
// Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
// Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1240px !default;
$screen-lg-min:              $screen-lg !default;
// Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$screen-xl:                  1440px !default;
$screen-xl-min:              $screen-xl !default;
$screen-xl-desktop:          $screen-xl-min !default;
$screen-lg-max:              ($screen-xl-min - 1) !default;




/** ------------------------------------------------------------- **/
/**                      12 - Grid system                         **/
/** ------------------------------------------------------------- **/

$grid-columns:              12 !default;
// Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
// Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
// Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;




/** ------------------------------------------------------------- **/
/**                     13 - Container sizes                      **/
/** ------------------------------------------------------------- **/
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
// For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
// For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1200px + $grid-gutter-width) !default;
// For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;

$container-xlarge-desktop:   (1410px + $grid-gutter-width) !default;
$container-xl:               $container-xlarge-desktop !default;


/** ------------------------------------------------------------- **/
/**                        14 - Navbar                            **/
/** ------------------------------------------------------------- **/

// Basics of a navbar
$navbar-height:                    60px !default;
// $navbar-margin-bottom:             $line-height-computed !default;
$navbar-margin-bottom: 						 0px !default;
$navbar-border-radius:             $border-radius-base !default;
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;
$navbar-collapse-max-height:       340px !default;

$navbar-default-color:             #777 !default;
$navbar-default-bg:                #f8f8f8 !default;
$navbar-default-border:            darken($navbar-default-bg, 6.5%) !default;

// Navbar links
$navbar-default-link-color:                #777 !default;
$navbar-default-link-hover-color:          #333 !default;
$navbar-default-link-hover-bg:             transparent !default;
$navbar-default-link-active-color:         #555 !default;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color !default;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%) !default;
$navbar-default-brand-hover-bg:            transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd !default;
$navbar-default-toggle-icon-bar-bg:        #888 !default;
$navbar-default-toggle-border-color:       #ddd !default;

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%) !default;
$navbar-inverse-bg:                         #222 !default;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 10%) !default;

// Inverted navbar links
$navbar-inverse-link-color:                 lighten($gray-light, 15%) !default;
$navbar-inverse-link-hover-color:           #fff !default;
$navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-link-disabled-color:        #444 !default;
$navbar-inverse-link-disabled-bg:           transparent !default;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color:          #fff !default;
$navbar-inverse-brand-hover-bg:             transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            #333 !default;
$navbar-inverse-toggle-icon-bar-bg:         #fff !default;
$navbar-inverse-toggle-border-color:        #333 !default;




/** ------------------------------------------------------------- **/
/**                         15 - Navs                             **/
/** ------------------------------------------------------------- **/

//=== Shared nav styles
$nav-link-padding:                          10px 15px !default;
$nav-link-hover-bg:                         $medium-tan !default;
$nav-disabled-link-color:                   $gray-light !default;
$nav-disabled-link-hover-color:             $gray-light !default;




/** ------------------------------------------------------------- **/
/**                          16 - Tabs                            **/
/** ------------------------------------------------------------- **/

$nav-tabs-border-color:                     #ddd !default;
$nav-tabs-link-hover-border-color:          $gray-lighter !default;
$nav-tabs-active-link-hover-bg:             $body-bg !default;
$nav-tabs-active-link-hover-color:          $gray !default;
$nav-tabs-active-link-hover-border-color:   #ddd !default;
$nav-tabs-justified-link-border-color:            #ddd !default;
$nav-tabs-justified-active-link-border-color:     $body-bg !default;




/** ------------------------------------------------------------- **/
/**                         17 - Pills                            **/
/** ------------------------------------------------------------- **/
$nav-pills-border-radius:                   $border-radius-base !default;
$nav-pills-active-link-hover-bg:            $component-active-bg !default;
$nav-pills-active-link-hover-color:         $component-active-color !default;




/** ------------------------------------------------------------- **/
/**                        18 - Pagination                        **/
/** ------------------------------------------------------------- **/

$pagination-color:                     $link-color !default;
$pagination-bg:                        #fff !default;
$pagination-border:                    #ddd !default;
$pagination-hover-color:               $link-hover-color !default;
$pagination-hover-bg:                  $gray-lighter !default;
$pagination-hover-border:              #ddd !default;
$pagination-active-color:              #fff !default;
$pagination-active-bg:                 $brand-primary !default;
$pagination-active-border:             $brand-primary !default;
$pagination-disabled-color:            $gray-light !default;
$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           #ddd !default;




/** ------------------------------------------------------------- **/
/**                          19 - Pager                           **/
/** ------------------------------------------------------------- **/

$pager-bg:                             $pagination-bg !default;
$pager-border:                         $pagination-border !default;
$pager-border-radius:                  15px !default;
$pager-hover-bg:                       $pagination-hover-bg !default;
$pager-active-bg:                      $pagination-active-bg !default;
$pager-active-color:                   $pagination-active-color !default;
$pager-disabled-color:                 $pagination-disabled-color !default;




/** ------------------------------------------------------------- **/
/**                        20 - Jumbotron                         **/
/** ------------------------------------------------------------- **/

$jumbotron-padding:              30px !default;
$jumbotron-color:                inherit !default;
$jumbotron-bg:                   $gray-lighter !default;
$jumbotron-heading-color:        inherit !default;
$jumbotron-font-size:            ceil(($font-size-base * 1.5)) !default;
$jumbotron-heading-font-size:    ceil(($font-size-base * 4.5)) !default;




/** ------------------------------------------------------------- **/
/**                21 - Form states and alerts                    **/
/** ------------------------------------------------------------- **/

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;




/** ------------------------------------------------------------- **/
/**                         22 - Tooltips                         **/
/** ------------------------------------------------------------- **/

$tooltip-max-width:           200px !default;
$tooltip-color:               #fff !default;
$tooltip-bg:                  #000 !default;
$tooltip-opacity:             .9 !default;

$tooltip-arrow-width:         5px !default;
$tooltip-arrow-color:         $tooltip-bg !default;




/** ------------------------------------------------------------- **/
/**                         23 - Popovers                         **/
/** ------------------------------------------------------------- **/

$popover-bg:                          #fff !default;
$popover-max-width:                   276px !default;
$popover-border-color:                rgba(0,0,0,.2) !default;
$popover-fallback-border-color:       #ccc !default;
$popover-title-bg:                    darken($popover-bg, 3%) !default;

$popover-arrow-width:                 10px !default;
$popover-arrow-color:                 $popover-bg !default;

$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
$popover-arrow-outer-color:           fade_in($popover-border-color, 0.05) !default;
$popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%) !default;




/** ------------------------------------------------------------- **/
/**                        24 - Labels                            **/
/** ------------------------------------------------------------- **/

$label-default-bg:            $gray-light !default;
$label-primary-bg:            $brand-primary !default;
$label-success-bg:            $brand-success !default;
$label-info-bg:               $brand-info !default;
$label-warning-bg:            $brand-warning !default;
$label-danger-bg:             $brand-danger !default;

$label-color:                 #fff !default;
$label-link-hover-color:      #fff !default;




/** ------------------------------------------------------------- **/
/**                         25 - Modals                           **/
/** ------------------------------------------------------------- **/

$modal-inner-padding:         15px !default;
$modal-title-padding:         15px !default;
$modal-title-line-height:     $line-height-base !default;

$modal-content-bg:                             #fff !default;
$modal-content-border-color:                   rgba(0,0,0,.2) !default;
$modal-content-fallback-border-color:          #999 !default; // Fallback for IE8

$modal-backdrop-bg:           #000 !default;
$modal-backdrop-opacity:      .5 !default;
$modal-header-border-color:   #e5e5e5 !default;
$modal-footer-border-color:   $modal-header-border-color !default;

$modal-lg:                    900px !default;
$modal-md:                    600px !default;
$modal-sm:                    300px !default;




/** ------------------------------------------------------------- **/
/**                          26 - Alerts                          **/
/** ------------------------------------------------------------- **/

$alert-padding:               15px !default;
$alert-border-radius:         $border-radius-base !default;
$alert-link-font-weight:      600 !default;

$alert-success-bg:            $state-success-bg !default;
$alert-success-text:          $state-success-text !default;
$alert-success-border:        $state-success-border !default;

$alert-info-bg:               $state-info-bg !default;
$alert-info-text:             $state-info-text !default;
$alert-info-border:           $state-info-border !default;

$alert-warning-bg:            $state-warning-bg !default;
$alert-warning-text:          $state-warning-text !default;
$alert-warning-border:        $state-warning-border !default;

$alert-danger-bg:             $state-danger-bg !default;
$alert-danger-text:           $state-danger-text !default;
$alert-danger-border:         $state-danger-border !default;




/** ------------------------------------------------------------- **/
/**                     27 - Progress Bars                        **/
/** ------------------------------------------------------------- **/

$progress-bg:                 #f5f5f5 !default;
$progress-bar-color:          #fff !default;
$progress-border-radius:      $border-radius-base !default;

$progress-bar-bg:             $brand-primary !default;
$progress-bar-success-bg:     $brand-success !default;
$progress-bar-warning-bg:     $brand-warning !default;
$progress-bar-danger-bg:      $brand-danger !default;
$progress-bar-info-bg:        $brand-info !default;




/** ------------------------------------------------------------- **/
/**                       28 - List Group                         **/
/** ------------------------------------------------------------- **/

$list-group-bg:                 #fff !default;
$list-group-border:             #ddd !default;
$list-group-border-radius:      $border-radius-base !default;

$list-group-hover-bg:           #f5f5f5 !default;
$list-group-active-color:       $component-active-color !default;
$list-group-active-bg:          $component-active-bg !default;
$list-group-active-border:      $list-group-active-bg !default;
$list-group-active-text-color:  lighten($list-group-active-bg, 40%) !default;

$list-group-disabled-color:      $gray-light !default;
$list-group-disabled-bg:         $gray-lighter !default;
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color:         #555 !default;
$list-group-link-hover-color:   $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;




/** ------------------------------------------------------------- **/
/**                          29 - Panels                          **/
/** ------------------------------------------------------------- **/

$panel-bg:                    #fff !default;
$panel-body-padding:          15px !default;
$panel-heading-padding:       10px 15px !default;
$panel-footer-padding:        $panel-heading-padding !default;
$panel-border-radius:         $border-radius-base !default;

$panel-inner-border:          #ddd !default;
$panel-footer-bg:             #f5f5f5 !default;

$panel-default-text:          $gray-dark !default;
$panel-default-border:        #ddd !default;
$panel-default-heading-bg:    #f5f5f5 !default;

$panel-primary-text:          #fff !default;
$panel-primary-border:        $brand-primary !default;
$panel-primary-heading-bg:    $brand-primary !default;

$panel-success-text:          $state-success-text !default;
$panel-success-border:        $state-success-border !default;
$panel-success-heading-bg:    $state-success-bg !default;

$panel-info-text:             $state-info-text !default;
$panel-info-border:           $state-info-border !default;
$panel-info-heading-bg:       $state-info-bg !default;

$panel-warning-text:          $state-warning-text !default;
$panel-warning-border:        $state-warning-border !default;
$panel-warning-heading-bg:    $state-warning-bg !default;

$panel-danger-text:           $state-danger-text !default;
$panel-danger-border:         $state-danger-border !default;
$panel-danger-heading-bg:     $state-danger-bg !default;




/** ------------------------------------------------------------- **/
/**                        30 - Thumbnails                        **/
/** ------------------------------------------------------------- **/

$thumbnail-padding:           4px !default;
$thumbnail-bg:                $body-bg !default;
$thumbnail-border:            #ddd !default;
$thumbnail-border-radius:     $border-radius-base !default;
$thumbnail-caption-color:     $text-color !default;
$thumbnail-caption-padding:   9px !default;




/** ------------------------------------------------------------- **/
/**                         31 - Wells                            **/
/** ------------------------------------------------------------- **/

$well-bg:                     #f5f5f5 !default;
$well-border:                 darken($well-bg, 7%) !default;




/** ------------------------------------------------------------- **/
/**                         32 - Badges                           **/
/** ------------------------------------------------------------- **/

$badge-color:                 #fff !default;
$badge-link-hover-color:      #fff !default;
$badge-bg:                    $gray-light !default;
$badge-active-color:          $link-color !default;
$badge-active-bg:             #fff !default;
$badge-font-weight:           bold !default;
$badge-line-height:           1 !default;
$badge-border-radius:         10px !default;




/** ------------------------------------------------------------- **/
/**                        33 - Breadcrumbs                       **/
/** ------------------------------------------------------------- **/

$breadcrumb-padding-vertical:   8px !default;
$breadcrumb-padding-horizontal: 15px !default;
$breadcrumb-bg:                 #f5f5f5 !default; // Background Heading
$breadcrumb-color:              #ccc !default; // Text color
$breadcrumb-active-color:       $gray-light !default; 
$breadcrumb-separator:          "/" !default;




/** ------------------------------------------------------------- **/
/**                         34 - Carousel                         **/
/** ------------------------------------------------------------- **/

$carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6) !default;
$carousel-control-color:                      #fff !default;
$carousel-control-width:                      15% !default;
$carousel-control-opacity:                    .5 !default;
$carousel-control-font-size:                  20px !default;
$carousel-indicator-active-bg:                #fff !default;
$carousel-indicator-border-color:             #fff !default;
$carousel-caption-color:                      #fff !default;




/** ------------------------------------------------------------- **/
/**                          35 - Close                           **/
/** ------------------------------------------------------------- **/

$close-font-weight:           bold !default;
$close-color:                 #000 !default;
$close-text-shadow:           0 1px 0 #fff !default;




/** ------------------------------------------------------------- **/
/**                           36 - Code                           **/
/** ------------------------------------------------------------- **/

$code-color:                  #c7254e !default;
$code-bg:                     #f9f2f4 !default;
$kbd-color:                   #fff !default;
$kbd-bg:                      #333 !default;
$pre-bg:                      #f5f5f5 !default;
$pre-color:                   $gray-dark !default;
$pre-border-color:            #ccc !default;
$pre-scrollable-max-height:   340px !default;




/** ------------------------------------------------------------- **/
/**                           37 - Type                           **/
/** ------------------------------------------------------------- **/

$component-offset-horizontal: 180px !default; // Horizontal offset for forms and lists.
$text-muted:                  $gray-light !default;
$abbr-border-color:           $gray-light !default; // Abbreviations and acronyms border color
$headings-small-color:        $gray-light !default; // Headings small color
$blockquote-small-color:      $gray-light !default; // Blockquote small color
$blockquote-font-size:        ($font-size-base * 1.25) !default; // Blockquote font size
$blockquote-border-color:     $gray-lighter !default; // Blockquote border color
$page-header-border-color:    $gray-lighter !default; // Page header border color
$dl-horizontal-offset:        $component-offset-horizontal !default; // Width of horizontal description list titles
$dl-horizontal-breakpoint:    $grid-float-breakpoint !default; // Point at which .dl-horizontal becomes horizontal
$hr-border:                   $gray-lighter !default;
