
$border: 2px solid $medium-tan;
$brown-border: 2px solid $darker-brown;
$mobileMaxWidth: 320px;
$mobileShadowSize: 15px;

/* Input styles for extra small screens and up */
@media (min-width: $screen-xs-min) {
	#mobile-nav {
		transition: .2s ease-in;
		width: 100%;
		max-width: $mobileMaxWidth;
		position: fixed;
		background: $lighter-brown;
		z-index: 9999999;
		top: 0px;
		overflow-y: scroll;
		box-shadow: 0px 0px $mobileShadowSize rgba($black, 0.6);

		&:not(.is-active) {
			transform: translateX(-($mobileMaxWidth + $mobileShadowSize));
		}

		&.is-active {
			transform: translateX(0px);
		}

		.nav.navbar-nav {
			margin: 0px;
			li {
				border-right: 0px;
				&.dropdown {
					overflow: visible;

					a {
						color: $gray;
					}
					.dropdown-menu {
						display: block;
						position: relative;
						width: 100%;
						float: none;
						box-shadow: none;
						background: none;
						margin: 0px;
						padding: 0px;

						a {
							color: $gray-dark;
							padding: 10px $double-padding;

							&:focus,
							&:hover,
							&.active {
								background: $darker-brown;
								color: $medium-tan;
							}
						}
					}
				}
			}

			&.pull-right {
				float: left !important;
				display: inline-block;
				li {
					float: left;
				}
				li:first-of-type {
					border-left: none;
				}
	
				li:last-of-type {
					border-right: none;
				}
			}
		}
	}
	header {
		background: $medium-tan;
		box-shadow: 0px 0px 5px rgba($black, 0.2);
		position: relative;

		#header-top {
			padding: $default-padding / 2 0px;

			.logo-contain {
				display: none;
			}

			.name, 
			.license {
				display: inline-block;
				line-height: 18px;
			}

			.name {
				float: left;
			}

			.license {
				float: right;
				text-align: right;
			}

			.hbap-link {
				display: none;
				text-align: right;
			}
		}
		#header-bottom {
			height: $navbar-height - 10px;
			background: $lighter-brown;
			position: absolute;
			z-index: 1;
			width: 100%;
			top: -62px;

			&:not(.moving) {
				top: 35px;
			}

			.navbar {
				display: none;
				border: 0px;
				width: 100%;
			}
			
			.logo-contain {
				float: right;
				height: 50px;
				display: inline-block;
				max-width: 80%;

				.logo {
					height: 50px;
					padding: 10px;
				}
			}

			&.moving {
				position: fixed;
				z-index: 9;
				transform: translateY(0px);
				transition: transform .2s ease;
				box-shadow: 0px 1px 2px rgba($black, 0.3);				
			}

			&.fixed {
				transform: translateY(62px);
			}
		}	
	}

	.nav.main-navs {
		li {
			border-right: $brown-border;

			&:not(.dropdown) {
				overflow: hidden;
			}

			a {
				color: $gray-dark;
				font-weight: 600;

				&.active,
				&:hover,
				&:focus {
					background: $darker-brown;
					color: $medium-tan;
				}
			}

			&.dropdown {
				&.open {
					.dropdown-toggle {
						background: $darker-brown;
						color: $medium-tan;
					}
				}
				.dropdown-menu {
					border: 0px;
					background: $lighter-brown;
					left: -2px;
					li {
						border: 0px;
					}
				}
			}
		}

		&.pull-right {
			li:first-of-type {
				border-left: $brown-border;
			}

			li:last-of-type {
				border-right: none;
			}
		}
	}
}
/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	#mobile-nav {
		display: none;
	}
	header {
		#header-top {
			.name, 
			.license {
				display: block;
				float: none;
				line-height: inherit;
			}

			.license {
				text-align: left;	
			}

			.logo-contain {
				display: block;
				text-align: center;

				.logo {
					height: 33px;
					margin: 8px 0px 9px;
				}
			}

			.hbap-link {
				display: block;
				span {
					text-align: right;
				}
				a {
					display: block;
				}
			}
		}
		#header-bottom {
			height: $navbar-height;

			&:not(.moving) {
				top: 65px;
			}

			.navbar {
				display: inline-block;

				.nav {
					li {
						a {
							font-size: 14px;
						}
					}
					&.pull-right {
						li {
							a {
								padding-left: 10px;
								padding-right: 10px;
							}
						}
					}
				}
			}

			.hamburger {
				display: none;
			}

			.logo-contain {
				display: none;
			}
		}
	}
	
}
/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {
	.navbar {
		display: block;
	}

	header {
		#header-top {
			.logo-contain {
				.logo {
					height: 50px;
					margin: 0px;
				}
			}
		}
	}
}
/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {

}