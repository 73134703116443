%label {
	margin-right: 5px;
	display: inline-block
}

.card-contain {
	&.project {
		.body-text {
			margin-top: $default-margin;
			p {
				margin-bottom: 0px;
			}
		}

		.project-img,
		.project-video {
			margin-top: $double-margin;
		}

		.label {
			@extend %label;
		}
	}
}

.project-announcement {
	img {
		display: block;
		margin: 0 auto;
	}

	.body-text {
		margin: $default-margin 0px;
	}

	.label {
		@extend %label;
	}
}

@media (min-width: $screen-xs-min) {
	.project-entry {
		margin-top: $double-margin;
	}
}

/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	.project-entry {
		margin-top: 0px;
	}
}

/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {}

/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {}