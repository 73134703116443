

@media (min-width: $screen-xs-min) {}
@media (min-width: $screen-sm-min) {}
@media (min-width: $screen-md-min) {}
@media (min-width: $screen-lg-min) {}

@media (max-width: $screen-xs-max) {
	.height-auto-xs-down {
		height: auto !important;
	}
}
@media (max-width: $screen-sm-max) {
	.height-auto-sm-down {
		height: auto !important;
	}
}
@media (max-width: $screen-md-max) {
	.height-auto-md-down {
		height: auto !important;
	}
}
@media (max-width: $screen-lg-max) {
	.height-auto-lg-down {
		height: auto !important;
	}
}