@media (min-width: $screen-xs-min) {
	.entry-nav {
		&.top {
			&.listing, &.project {
				margin: 0 0 $default-margin;
			}
		}

		.all {
			display: none;
		}

		.previous {
			float: left;
		}

		.next {
			float: right;
		}
	}
}

/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	.entry-nav {
		&.top {	
			&.listing {
				margin: 0px $double-margin $default-margin;
			}

			&.project {
				margin: $double-margin 0;
			}
		}

		.all {
			display: block;
		}

		.previous,
		.next {
			float: right;
		}

		.next {
			margin-left: $double-margin;
		}
	}
}

/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {}

/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {}