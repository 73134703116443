#footer {
	background: $medium-tan;
	box-shadow: 0px 0px 2px rgba($black, 0.4);
	#footer-top {
		padding: $double-padding 0;

		.nav {
			li {
				a {
					padding: 0px;
					color: #555;
					display: inline-block;

					&:hover, &:focus {
						background:transparent;
						color: $brand-primary;
					}
				}
			}

			&.recent-listings {
				li {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}

	#footer-bottom {
		background: $lighter-brown;
		border-top: 2px solid $medium-tan;
		padding: $default-padding / 2 0;
		color: $gray-dark;

		img {
			height: $double-margin;
			margin-right: $default-margin;
		}

		p {
			line-height: $double-margin;
			@extend %no-mrg;
		}
	}	
}


/* Input styles for extra small screens and up */
@media (min-width: $screen-xs-min) {
	#footer {
		#footer-top {
			h4.footer-title.first {
				margin-top: 0px;
			}
		}
	}
	
	.xs-only {
		display: block;

		.trulia-zillow {
			max-width: 300px;;
		}

		.logo {
			max-width: 300px;
			width: 100%;
			display: block;

			&.oh2h {
				margin: $double-margin 0px $default-margin;
			}

			

			&.trulia {
				margin-top: $default-margin;
				max-width: 40%;
				float: left;
			}

			&.zillow {
				margin-top: $default-margin;
				max-width: 40%;
				float: right;
			}
		}
	}

	.sm-up {
		display: none;
	}
}
/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	#footer {
		#footer-top {
			h4.footer-title.first {
				margin-top: 11px;
			}
		}
	}

	.xs-only {
		display: none;
	}

	.sm-up {
		display: block;

		.logo {
			&.oh2h {
				margin: 11px 0px $default-margin;
			}
		}
	}
}
/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {}
/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {}