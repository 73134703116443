%btn-card {
	width: 100%;
	display: block;
	padding: 10px;
	text-align: center;
}


.btn {
	&:hover, &:focus {
		border-color: transparent !important;
		text-decoration: none !important;
	}

	&.btn-primary:hover,
	&.btn-primary:focus {
		background: darken($brand-primary, 5%);
	}
}

a:hover.btn-card {text-decoration: none;}

.btn-card {
	@extend %btn-card;

	&.btn-primary {
		background: $white;
		border-top: 2px solid darken($medium-tan, 3%);
		color: $brand-primary;
		font-weight: bold;
	}

	&.btn-primary-inverse {
		background: $brand-primary;
		border-top: 2px solid darken($brand-primary, 3%);
		color: $medium-tan;
	}
}

.button-container {
    color: #333;
	box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
	background: #fefefe;
	padding: 6px 10px;
	display: inline-block;
	float: right;
	@include position(relative, null, -25px, -6px, null);
	width: 200px;
	transition: all .2s ease-in;
	
	text-align: right;

	&:hover {
		color: $black;
		width: calc(100% + 40px);	
		text-decoration: none;

		span {
			transform: translateX(-20px);
		}

		.fa-arrow-right {
			transform: translateX(-15px);
		}
	}

	.fa-arrow-right {
		transition: all .2s ease-in;
		transform: translateX($double-margin);
		
	}

	span {
		transition: all .2s ease-in;
		transform: translateX(0px);
		display: inline-block;
	}
}

.hide-filter {
	margin-bottom: $double-margin;
}

.scroll-top.btn {
	position: fixed;
	transition: .2s ease-in;
	transform: translateX(50px);
    bottom: $default-margin;
    right: 0px;

	&.show {
		position: fixed;
		transition: .2s ease-in;
		transform: translateX(-15px);
		bottom: $default-margin;
	}
}

/* Input styles for extra small screens and up */
@media (min-width: $screen-xs-min) {
	.hide-filter {
		.filter-text {
			display: inline-block;
		}
	}

	.filter-scroll.filter {
		display: block;
		margin-bottom: $default-margin;
		background: $white;
		box-shadow: 0px 1px 2px rgba($black, 0.2);

		&:hover, &:focus {
			color: $brand-primary;
		}
	}
}
/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	.hide-filter {
		.filter-text {
			display: none;
		}
	}

	.filter-scroll.filter {
		display: none;
	}
}
/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {}
/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {}