.listing-entry {
	.entry-details {
		.label {
			width: calc(100% + 30px);
			padding-right: $double-padding;
			display: block;
			line-height: 1.4em;
			position: relative;
			right: $default-margin;
			bottom: $default-margin;
			text-align: left;
			padding: 5px $default-padding;
			font-size: 100%;
		}
	}
	
	.slick-slide:not(.slick-center) {
		transform: scale(.9);
	}

	.slider {
		display: none;
	}

	.slider .card-contain {
		margin-bottom: 2px;
	}

}

.dots {
	max-width: 100px;
	overflow: hidden;
	
	.slick-dots {
		bottom: 5px;
		width: calc(100% - 30px);

		li {
			width: 5px;
			height: 5px;
			margin: 1px;
			
			&.slick-active {
				button {
					.fa-circle {
						color: $brand-primary;
					}
				}
			}

			button {
				padding: 0;
				width: auto;
				height: auto;

				&:before {
					content: none;
				}

				.fa-circle {
					color: lighten($brand-primary, 20%);
					font-size: 6px;
				}
			}
		}
	}
}

// Global keep out of body scope
.share {
	display: inline-block; 
	margin-left: 20px; 
	line-height: 1.4em;

	.fb-share-button  {
		display: inline;
	}	
}

.label .share a {
	color: #fff;
	margin-left: 5px;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

ul.listing-features {
	padding-left: 20px;
}

/* Input styles for extra small screens and up */
@media (min-width: $screen-xs-min) {
	#header {
		.container-fluid { 
			max-width: 1600px; 
			padding: 0px $default-margin;
			margin: $double-margin auto 7px; 
		}
	}

	.listing-entry {
		.map {
			height: 350px;
		}
	}
}
/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	#header {
		.container-fluid { 
			padding: 0px $double-padding;
			margin: $double-margin auto; 
		}
	}

	.listing-entry {
		.map {
			height: auto;
		}
	}
}
/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {}
/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {}