/* Input styles for extra small screens and up */
@media (min-width: $screen-xs-min) {
	.partner {
		.img-contain {
			img:not(:last-of-type) {
				margin-bottom: $default-margin;
			}
		}
	}
}
/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {}
/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {}
/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {}