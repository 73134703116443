.full-bleed {
	background: $lightest-tan; 
	box-shadow: inset 0px 0px 4px rgba($black, 0.4); 
	margin: $double-margin auto;
	overflow: hidden;
	padding: $double-padding 0;

	.card-contain.tan.cta {
		margin: $double-margin auto;
		border: $default-margin solid #fefefe;
		overflow: hidden;
		padding: 0px;
		box-shadow: 0px 1px 2px rgba(0,0,0,0.3);

		.shadow-contain {
			width: 100%;
			box-shadow: inset 0px 0px 2px rgba(0,0,0,0.3);
			padding: $default-padding $default-padding 0px $default-padding;
			overflow: hidden;

			.fa:not(.fa-arrow-right) {
				font-size: 80px;
				text-align: center;
				display: block;
				@extend %no-mrg;
			}
		}
	}
}


.card-contain {
	background: $lightest-tan;
	margin-bottom: $double-margin;
	padding: $default-padding;
	box-shadow: 0px 1px 2px rgba($black, 0.2);	
	clear: both;

	&.tan {
		background: $medium-tan;
	}

	&.cta {
		h4 {
			text-align: center;
		}
	}
}


.card-contain.tan.cta {
	margin: $double-margin auto;
	border: $default-margin solid #fefefe;
	overflow: hidden;
	padding: 0px;
	box-shadow: 0px 1px 2px rgba(0,0,0,0.3);

	.shadow-contain {
		width: 100%;
		box-shadow: inset 0px 0px 2px rgba(0,0,0,0.3);
		padding: $default-padding $default-padding 0px $default-padding;
		overflow: hidden;

		.fa:not(.fa-arrow-right) {
			font-size: 80px;
			text-align: center;
			display: block;
			@extend %no-mrg;
		}
	}
}