.section-header {
	background: $lightest-tan;
	display: inline-block;
	box-shadow: 0px 1px 2px rgba($black, 0.2);
	margin: $double-margin auto;

	h2 {
		margin: 20px 20px 15px;
	}

	.bottom {
		background: $brand-primary;
		box-shadow: 1px 1px 1px rgba($black, 0.2);
		@include position(relative, null, null, -2px, 0);

		h5 {
			color: $white;
			margin: 0 20px;
		}
	}
}


/* Input styles for extra small screens and up */
@media (min-width: $screen-xs-min) {
	.section-header {
		width: 100%;

		.bottom {
			min-width: 100%;

			h5 {
				line-height: 20px;
				padding: 10px 0px;
			}
		}
	}
}

/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	.section-header {
		min-width: 275px;
		width: auto;
		text-align: left;

		.bottom {
			min-width: 120%;
		}
	}
}

/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {
	.section-header {
		h2 {
			margin: $double-margin $double-margin 25px;
		}

		.bottom {
			h5 {
				margin: 0 $double-margin;
			}
		}
	}
}

/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {}