$background-color: rgba($white, 0.8);

#testimonials {
	padding-bottom: 0px !important;
	.vegas-wrapper {
		background: rgba($white, 0.4);
		padding: 0px !important;
	}
}

.testimonial-wrapper {
	padding: 35px 0px 35px;
	background-color: $background-color;
	box-shadow: 0px 1px 2px rgba($black, 0.3);

	.title {
		border-bottom: 4px solid $brand-primary;
		max-width: 600px;
		border-radius: 2px;

		h4 {
			text-align: center;
			// font-weight: bold;
			line-height: 30px;
		}
	}
}

.testimonial-slider {
	// padding: 35px 0px 50px;
	// background-color: $background-color;
	// box-shadow: 0px 1px 2px rgba($black, 0.3);

	.testimonial {
		display: none;

		&:first-of-type {
			display: block;
		}
	}

	.text {
		max-width: 80%;
		text-align: center;
		margin: 0 auto;
		font-style: italic;
	}

	.slick-arrow {
		width: 30px;
		height: 30px;
		z-index: 1;
	}

	.slick-next {
		right: 10px;
	}

	.slick-prev {
		left: 10px;
	}

	.slick-dots {
		bottom: -30px;

		li{
			&.slick-active {
				button:before {
					color: $brand-primary;
				}
			}
		}
	}
}

@media (min-width: $screen-xs-min) {
	.testimonial-wrapper {
		margin: $double-margin 0px !important;

		.title {
			margin: 0px 15px 15px;
		}
	}

	.testimonial-slider {
		// margin: $double-margin 0px !important;
		.text {
			p {
				font-size: 16px !important;			
			}
		}
	}

	.slick-dots {
		bottom: 20px;

		li{
			&.slick-active {
				button:before {
					font-size: 12px;
				}
			}

			button:before {
				font-size: 10px;
			}
		}
	}
}

/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	.testimonial-wrapper {
		margin: 75px 0px !important;

		.title {
			margin: 0px auto 15px;
		}
	}

	.testimonial-slider {
		// margin: 75px 0px !important;
		.text {
			p {
				font-size: 18px !important;			
			}
		}
	}
}

/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {}

/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {}