.label {
	border-radius: 0px !important;
}

.label-brown {
	background-color: $darker-brown;
}

.project {
	.label {
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 5px;
		display: inline-block;
		padding: .3em .6em;
	}

	&.card {
		.label.label-success { // Funded and Built
			display: none;
		}
	}
}