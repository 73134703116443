@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "../fonts/slick/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: black !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: $fa-var-arrow-right !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 10px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-next::before,
.slick-prev::before {
	display: none;
}

.slick-next,
.slick-prev {
	color: $brand-primary !important;
	font-size: 24px !important;
}