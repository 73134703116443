/// ------------------------------------------------------------- ///
///                      Base Styles                              ///
///     Check _variables.scss before making adjustsments here     ///
/// ------------------------------------------------------------- ///

* {
  box-sizing: border-box;
}

html, body {
	height: 100%;
	margin: 0 auto;
	position: relative;
	padding: 0px;
}

main {
	overflow: hidden;
}

a {
	cursor: pointer;
}

img {
	max-width: 100%;
}

.row-divider {
	background-color: $lighter-brown; 
	height: 1px; 
	border: 0px; 
	margin: 0px auto $double-margin;
}

.pagination > li > a {
	padding: 6px 10px !important;
}


/* Input styles for extra small screens and up */
@media (min-width: $screen-xs-min) {
	html, body {
		@include font-sizes(14px);
	}

	main {
		padding-top: 50px;
	}

	.image-slider {
		display: block;
	}

	.image-repeat {
		display: none;
	}
}

/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	html, body {
		@include font-sizes(16px);
	}

	main {
		padding-top: 60px;
	}

	.image-slider {
		display: none;
	}

	.image-repeat {
		display: block;
	}
}

/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {}

/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {}