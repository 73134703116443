// Variables
// --------------------------

$fa-sass-asset-helper: false !default;

$fa-font-path: if($fa-sass-asset-helper, "font-awesome/", "../fonts/font-awesome/") !default;
$fa-font-name: "fontawesome-webfont" !default;

$fa-font-size-base:   14px !default;
$fa-line-height-base: 1 !default;
$fa-css-prefix:       fa !default;
$fa-version:          "4.7.0" !default;
$fa-border-color:     #eee !default;
$fa-inverse:          #fff !default;
$fa-li-width:         (30em / 14) !default;

$fa-var-arrow-left: "\f060";
$fa-var-arrow-right: "\f061";