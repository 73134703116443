.contact-card {
	a:not(.btn) {
		color: $text-color;

		&:hover, &:focus {
			color: $brand-primary;
		}
	}

	.btn {
		width: 100%;
		margin-top: 5px;
	}
}

.card {
	a {
		display: block;
		box-shadow: 0px 1px 2px rgba($black, 0.3);
		transition: all .1s ease-in;;
		background: $white;
		margin-bottom: $default-margin;
		@include position(relative, 0px, null, null, null);
		overflow: hidden;

		&:hover {
			text-decoration: none;
			box-shadow: 0px 5px 15px rgba($black, 0.1);
			transition: all.1s ease-in;
			top: -3px;
		}

		&:focus {
			text-decoration: none;
		}

		.card-img {
			float: left;
			width: 30%;
			background-size: cover;
			background-position: center center;

			img {
				display: none;
			}
		}

		.card-details {
			width: 70%;
			padding: 5px;
			display: inline-block;
			float: right;

			.project-title {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			
			h5 {
				font-weight: 300;	
				color: $text-color;
			}

			h4 {
				font-weight: 300;	
				color: $text-color;

				&.price {
					margin-top: 3px;
					margin-bottom: 0px;
					font-weight: 600;
					display: inline-block;
					float: right;
				}					
			}

			p {
				color: $text-color;
				margin-bottom: 11px;

				&.details {
					margin-top: 13px; // 2px offset label
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				&.address {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-bottom: 0px;
					margin-top: 13px;

					.mobile-only {
						display: inline;
					}
				}

				&.cityStateZip {
					display: none;
				}
			}

			
			.label {
				line-height: 1.1;
				font-size: 85%;
				font-weight: 600;
				margin-right: 5px;
			}
		}
	}
}


/* Input styles for extra small screens and up */
@media (min-width: $screen-xs-min) {
	.card-title {
		margin-top: 20px;
	}
}
/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	.card {
		margin-bottom: $default-margin * 2;
	}

	.card-title {
		margin-top: 0px;
	}
}
/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {
	.last-hide:last-of-type {
		display: none;
	}
	.listing-col:not(.col-lg-3) {
		.card {
			a {
				.card-details {
					.price {
						font-size: 16px !important;
					}
				}
			}
		}
	}
	.card {
		a {
			.card-img {
				float: none;
				width: 100%;
				height: auto !important;
				background-image: none !important;
					
				img {
					display: block;
				}
			}

			.card-details {
				padding: $default-padding;
				width: 100%;
				display: block;
				float: none;
				height: auto !important;

				p {
					&.address {
						margin-bottom: 0px;
						.mobile-only {
							display: none;
						}
					}
	
					&.cityStateZip {
						display: block;
						margin-bottom: 0px;
					}
				}
			}
		}
	}
}
/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {
	.last-hide:last-of-type {
		display: block;
	}

	.listing-col:not(.col-lg-3) {
		.card {
			a {
				.card-details {
					.price {
						font-size: 20px !important;
					}
				}
			}
		}
	}
}