#filter {
	.btn.btn-primary {
		width: 100%;
	}

	.filter-header {
		margin-bottom: $default-margin;
	}
}

.collapse-btn {
	.btn {
		box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
		width: 100%;
	}
}