/// ------------------------------------------------------------- ///
///                     Grid Modifications                        ///
///         Used to create an even 5 column grid option.          ///
/// ------------------------------------------------------------- ///
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: $default-padding;
    padding-left: $default-padding;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	.col-sm-5ths {
        width: 20% !important;
        float: left;
    }
}

/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {
	.col-md-5ths {
        width: 20% !important;
        float: left;
    }
}

/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {
	.col-lg-5ths {
        width: 20% !important;
        float: left;
    }
}