.btn-copy{
	background: transparent;
	border: 0px;
	padding: 0px;
	margin-left: 5px;
	position: relative;
	z-index: 9;

	&:not(.clicked) {
		&:after, &:before {
			display: none !important;
		}
	}

	&.clicked {
		&:after, &:before {
			display: inline-block !important;
		}
	}
}