@mixin cta($position) {
	h3 {
		text-align: $position;
		color: $brand-primary;
		margin: 0;
		padding: 0 $default-padding;
	}
}

#hero {
	.card-contain {
		padding: 0;
		margin: 0;

		#hero-content {
			display: block;
			overflow: hidden;
			
			.hero-details {
				width: 240px;
				padding: $default-padding;
				background: rgba($lightest-tan, 0.9);
				margin: $default-margin auto;
			}
		
			.hero-cta {
				background: rgba($lightest-tan, 0.9);
				width: 100%;
		
				&.left-cta {
					@include cta(left);
				}
		
				&.center-cta {
					@include cta(center);
				}
		
				&.right-cta {
					@include cta(right);
				}
			}
		}
	}
}

/* Input styles for extra small screens and up */
@media (min-width: $screen-xs-min) {
	#hero {
		.container {
			width: 100%;
			padding: 0px;

			#hero-content {
				.hero-cta {
					h3 {
						line-height: 1.6em;
					}
				}
			}	
		}
	}
}
/* Input styles for small screens and up */
@media (min-width: $screen-sm-min) {
	#hero {
		.container {
			#hero-content {
				.hero-details {
					margin: $default-margin;
				}
		
				.hero-cta {
					h3 {
						line-height: 2.2em;
					}
				}
			}
		}
	}
}
/* Input styles for medium screens and up */
@media (min-width: $screen-md-min) {}
/* Input styles for large screens and up */
@media (min-width: $screen-lg-min) {
	#hero {
		margin-top: $double-margin;
		
		.container {
			max-width: 1230px;
			padding: 0px $default-padding;
		}
	}
}